import React from "react";
import ReactGA from "react-ga";
import { Image, LinkBox, LinkOverlay, WrapItem } from "@chakra-ui/react";

function GridItem({ img, alt, link, name }) {
  return (
    <WrapItem>
      <LinkBox zIndex={1}>
        <LinkOverlay
          href={link}
          onClick={() =>
            ReactGA.event({
              category: name,
              action: "Clicked link",
            })
          }
        >
          <Image boxSize="200px" src={img} alt={alt} rounded="md" />
        </LinkOverlay>
      </LinkBox>
    </WrapItem>
  );
}

export default GridItem;
